<template>
  <div class="page-view">
    <div class="service" style="margin-bottom: 40px">
      <div style="margin-bottom: 40px">
        <h1 style="font-weight: bolder">数字建筑平台应用系统</h1>
      </div>
      <div class="service-box">
        <div class="service-item" v-for="(item, index) in serviceList" :key="index + 6" @click="handItem(item)">
          <div class="service-header">
            <el-image style="width: 32px; height: 32px; margin-right: 8px" :src="item.icon"></el-image>
            <span class="service-text">{{ item.name }}</span>
          </div>
          <div style="text-align: start">{{ item.symbol }}</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import { mapState } from "vuex";
import {
  urlCommonColumnList,
  urlSystemList,
  getCatory,
  getIndustryInformationPage,
  urlBrowseUserStatTheme1,
  urlBrowseRegUserStatTheme1,
} from "@/api/engineering";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home" },
        { label: "资质查询", name: "quation" },
        { label: "金融服务", name: "record" },
        { label: "招标投标", name: "personnel" },
        { label: "机械管理", name: "project" },
        { label: "造价咨询", name: "certificate" },
        { label: "智慧工地", name: "creditscore" },
      ],
      activeTab: "home",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() { },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    LoginForm,
    subMenu,
  },
  mounted() {
    document.title = "邦建通数字建筑平台";
    this.init();
  },
  methods: {
    handVideo(e) {
      console.log("eeee", e);
      console.log("xxx", this.$refs.viodes);
      if (this.$refs.viodes.length > 0) {
        for (let i = 0; i < this.$refs.viodes.length; i++) {
          this.$refs.viodes[i].pause();
        }
      }
      // this.$refs.viodes[0].pause()
    },

    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      // this.$router.replace(toPage);
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },
    init() {
      this.geturlCommonColumnList();
      this.geturlSystemList();
      this.getCatory();
      this.getIndustryInformation();
      // this.getUrlBrowseUserStatTheme1()
      // this.getUrlBrowseRegUserStatTheme1()
    },
    // 获取访问量
    async getUrlBrowseUserStatTheme1() {
      try {
        const { data } = await urlBrowseRegUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseRegUserStatTheme1", error);
      }
    },
    // 获取注册用户数
    async getUrlBrowseRegUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseUserStatTheme1", error);
      }
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          // pageSize: 100,
          // pageNum: 1,
          current: 1,
          size: 20,
          industryInformationClassId: -1,
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/home/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },

    toEnterpriseService(routerPath = "/insurance/home/index") {
      const host = window.location.host;
      let targetUrlPrefix = "";
      // TODO: 测试环境条测试，正式跳正式
      if (
        host.includes("dev.yanxin7.cn") ||
        host.includes("localhost") ||
        host.includes("192.168")
      ) {
        targetUrlPrefix = "https://dev.yanxin7.cn" + "/bxbh/#";
      } else {
        targetUrlPrefix = window.location.origin + "/bxbh/#";
      }
      window.open(targetUrlPrefix + routerPath);
    },

    toPath(url) {
      console.log("url", url);

      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },

    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.companyList = data.filter((item) => item.type == "home");
        console.log("data=xx==>", data);
      } catch (error) {
        console.log("ee", error);
      }
    },

    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },

    handItem(item) {
      window.open(item.params);
    },
    handNavItem(item, index) {
      this.navActive = index;
    },
  },
};
</script>
<style>
.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.info-left_video .el-carousel__arrow {
  font-size: 30px !important;
  background-color: rgba(31, 45, 61, 0.1);
  color: white;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.main-header {
  // background-image: url(~@/assets/images/bjtsy/hb1.png);
  background-image: url("https://oss.yanxin7.cn/2023-07-14/a07d8d06364411dc4c8cfd6f86b34680");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}

.search-for {
  width: 100%;
  height: 330px !important;
  color: white;
  text-align: center;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  background-color: #0460e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.nav-left {
  width: 1200px;
  margin: 0 auto;
}

/* 浮窗样式 */
.suspended_box {
  position: fixed;
  bottom: 400px;
  z-index: 20;
  right: 20px;
  font-size: 16px;
  width: 80px;
  height: 222px;
}

.kefu {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  position: relative;
  box-sizing: border-box;
  width: 80px;
  height: 272px;
  border-radius: 16px;
  padding: 20px 10px;
}

.qrcode_content {
  position: absolute;
  bottom: 32%;
  z-index: 20;
  right: 100px;
  display: none;
}

.business_content {
  position: absolute;
  bottom: -35%;
  z-index: 20;
  right: 100px;
  display: none;
}

.pp {
  width: 165px;
  height: 165px;
  margin-top: 19px;
  margin-left: 17px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

.to_top {
  width: 78px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px;
  padding-top: 14px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #333e4d;
  cursor: pointer;
}

.to_top_img {
  width: 80px;
  /* height: 60px !important; */
}

.to_top_img img {
  width: 30px !important;
  height: 30px !important;
}

.qr_1 {
  width: 60px;
  height: 75px;
}

.pt-22 {
  padding-top: 11px;
}

.qr_1:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_1:hover+.qrcode_content {
  display: block;
}

.qr_code1 {
  width: 30px;
  height: 30px;
  background: url("https://oss.yanxin7.cn/2023-08-01/0951fb1d71c19c31f1eae1cf2588e74d") no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code {
  width: 30px;
  height: 30px;
  background: url("https://oss.yanxin7.cn/2023-07-03/0b35896e890f7560f862eff53ecb127e") no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code2 {
  width: 30px;
  height: 30px;
  background: url(~@/assets/images/bjtsy/mbz11846.png) no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  /* margin-top: 20px; */
  margin-left: 15px;
  cursor: pointer;
  /* position:relative */
}

.qr_code3 {
  width: 30px;
  height: 30px;
  background: url("https://oss.yanxin7.cn/2023-07-03/92b14e7bb43b9d363583f709984b969d") no-repeat;
  background-size: cover;
  margin-bottom: 1px;
  margin-left: 15px;
  cursor: pointer;
}

.code_c {
  font-size: 14px;
  text-align: center;
}

.erweima {
  text-align: center;
  width: 200px;
  height: 240px;
  top: -7px;
  /* left: -100px; */
  background: #fff;
  /* border: 1px solid #CCC; */
  float: right;
  cursor: default !important;
  box-shadow: 0px 3px 16px 1px rgba(41, 96, 222, 0.16);
  border-radius: 6px 6px;
}

.qr_2 {
  width: 60px;
  height: 75px;
}

.qr_2:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_2:hover+.gzh_content {
  display: block;
}

.qr_3:hover {
  background-color: #eff2f8;
  border-radius: 6px 6px;
}

.qr_3:hover+.business_content {
  display: block;
}

.qr_3 {
  width: 60px;
  height: 75px;
}

.msg {
  text-align: left;
  margin-left: 32px;
  margin-top: 40px;
  font-size: 16px;
  color: #5c6681;
}

::v-deep .el-backtop {
  width: 20px;
}

.msg_2 {
  width: 150px;
  text-align: left;
  margin-left: 32px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 18px;
}

.fw-b {
  font-weight: bold;
}

.fz-19 {
  font-size: 19px;
}

.back-btn {
  margin-left: 44px;
}

.img1 {
  width: 30px;
  height: 30px;
}

.img2 {
  margin-left: 12px;
  padding-bottom: 8px;
}

.txt-fs {
  font-size: 18px;
  padding-left: 10px;
}

.img-t1 {
  width: 18px;
  height: 18px;
}

.list-t {
  padding-top: 3px;
  margin-right: 5px;
}

.btn-person {
  background-color: #ffffff;
  width: 70px;
  padding-top: 3px;
  border: none;
  cursor: pointer;
}

.mb-10 {
  margin-bottom: 10px;
}

/* // */
::v-deep .el-tabs--border-card>.el-tabs__content {
  padding: 0px;
}

.min-w {
  min-width: 1320px;
}

::v-deep .el-header {
  padding: 10px 0px 0px 0px;
}

/* 菜单箭头 */
::v-deep .el-tabs__nav-next,
::v-deep .el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  color: #ffffff;
}

::v-deep .el-tabs__nav-next.is-disabled,
::v-deep .el-tabs__nav-prev.is-disabled {
  color: #ffffff;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  /* background-color: #F5F7FA; */
  /* border-bottom: 1px solid #E4E7ED; */
  margin: 0;
}

::v-deep .el-tabs--bottom .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--bottom.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--left>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top .el-tabs--right>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--border-card>.el-tabs__header .el-tabs__item:nth-child(2),
.el-tabs--top.el-tabs--card>.el-tabs__header .el-tabs__item:nth-child(2) {
  padding-left: 0px;
}

.page-view {
  width: 1520px;
  margin: 0 auto;
  margin-top: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header {
  border-bottom: none;
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1520px;
  margin: 0 auto;
  margin-top: 23px;
}

.info h1 {
  margin-bottom: 20px;
  font-weight: bolder;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-right {
  width: 100%;
}

.info-left {
  margin-right: 40px;
}

.info-left_video {
  width: 510px;
  height: 287px;
}

.info-left_video video {
  width: 100%;
  height: 100%;
}

.video-box-item {
  width: 31vw;
  height: 19vw;
  object-fit: fill;
}

.info-left_list {
  height: 328px;
  opacity: 1;
  overflow: auto;
}

.info-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  cursor: pointer;
  width: 1000px;
}

.text-end {
  text-align: end;
  width: 100%;
}

/* 隐藏垂直滚动条 */
.info-left_list::-webkit-scrollbar {
  width: 0.5em;
  /* 设置滚动条的宽度 */
}

.list-icon {
  width: 7px;
  height: 7px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 24px;
}

.list-item_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 656px;
  display: inline-block;
  text-align: start;
}

.info-list-item:hover {
  color: #2288fc !important;
}

.info-list-item a:hover {
  color: #2288fc !important;
}

.service-box {
  display: flex;
  width: 1520px;
  align-items: center;
  flex-wrap: wrap;
}

.service-box2 {
  display: flex;
  width: 1200px;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  /*  */
  box-sizing: border-box;
}

.service-item {
  width: 286px;
  height: 120px;
  background: #ffffff;

  opacity: 1;
  border: 1px solid #e2e5ec;
  margin-bottom: 18px;
  padding: 16px 13px;
  margin-right: 17px;
}

.service-box :nth-child(5n) {
  margin-right: 0px !important;
}

.service-box2 :nth-child(6n) {
  margin-right: 0px !important;
}

.parent-item {
  width: 180px;
  margin-bottom: 18px;
  margin-right: 23px;
}

.service-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.service-text {
  width: 80px;
  font-size: 20px;
  font-weight: 400;
}

.service-item:hover {
  cursor: pointer;
  box-shadow: 3px 3px 16px 1px rgba(61, 145, 255, 0.16);

  .service-text {
    color: #631b37;
  }
}

a {
  text-decoration: none;
  color: black;
}

.nav-list {
  display: flex;
  width: 979px;
  font-size: 20px;
  box-sizing: border-box;
  color: #212121;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 0.5em;
  /* 设置滚动条的宽度 */
}

.new-line {
  margin-top: -0px;
  width: 979px;
  border-bottom: 1px solid rgb(212, 209, 209);
}

.nav-active {
  position: relative;
  border-bottom: 2px solid #2288fc;
  padding-bottom: 10px;
}

.nav-item {
  height: 50px;
  cursor: pointer;
  width: 53px;
  margin-right: 30px;
  white-space: nowrap;
  position: relative;
}

.flex-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
