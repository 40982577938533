<template>
  <div>
    <div class="info-item info-left">
      <h1 style="margin-bottom: 30px">集团动态</h1>
      <div class="info-left_video">
        <el-carousel
          trigger="click"
          @change="handVideo()"
          :interval="5000"
          arrow="always"
          :autoplay="false"
        >
          <el-carousel-item
            class="video-box"
            v-for="(item, index) in videoUrlList"
            :key="index"
          >
            <video
              ref="viodes"
              :poster="item.urlCover"
              class="video-box-item"
              controls
              :src="item.url"
            ></video>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { urlSystemList } from "@/api/engineering";

export default {
  data() {
    return {
      videoUrlList: [],
    };
  },
  mounted() {
    this.geturlSystemList();
  },
  methods: {
    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },
  },
};
</script>

<style scoped lang="less">
.info-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.info-left_video {
  width: 510px;
  height: 287px;
}
.info-left_video video {
  width: 100%;
  height: 100%;
}

.video-box-item {
  width: 510px;
  height: 287px;
  object-fit: fill;
}


.info-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 39px;
  cursor: pointer;
  width: 1000px;
}
.text-end {
  text-align: end;
  width: 100%;
}
/* 隐藏垂直滚动条 */
.info-left_list::-webkit-scrollbar {
  width: 0.5em; /* 设置滚动条的宽度 */
}
.list-icon {
  width: 7px;
  height: 7px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 24px;
}
.list-item_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 656px;
  display: inline-block;
  text-align: start;
}

.info-list-item:hover {
  color: #2288fc !important;
}
.info-list-item a:hover {
  color: #2288fc !important;
}
</style>
