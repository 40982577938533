<template>
  <div class="main-header">
    <div class="search-for"></div>
    <div class="nav-width">
      <div class="nav-left">
        <el-tabs class="menu-item" id="content" type="border-card" stretch v-model="activeTab"
          @tab-click="handleTabClick">
          <el-tab-pane :label="item.label" v-for="(item, index) in menuList" :key="index" :name="item.name">
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import LoginForm from "@/components/login-form";
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [
        { label: "首页", name: "home" },
        { label: "应用系统", name: "ecosystem" },
        { label: "行业资讯", name: "news" },
        { label: "荣誉证书", name: "books" },
        { label: "战略合作", name: "partner" },
        { label: "联系我们", name: "about" },
      ],
    },
    activeTab: {
      type: String,
      default: "home",
    },
  },
  data() {
    return {
      // activeTab: "about",
      // menuList: [
      //   { label: "首页", name: "home" },
      //   { label: "生态体系", name: "ecosystem" },
      //   { label: "行业资讯", name: "news" },
      //   { label: "荣誉证书", name: "books" },
      //   { label: "战略合作", name: "partner" },
      //   { label: "联系我们", name: "about" },
      // ],
    };
  },
  components: {
    LoginForm,
  },
  methods: {
    handleTabClick(tab) {
      console.log("----tap", tab.name);
      if (tab.name == "ecosystem") {
        this.$router.push("/subwebsite/ecosystem");
      }

      if (tab.name == "home") {
        this.$router.push("/subwebsite/subwebsite");
      }
      if (tab.name == "partner") {
        this.$router.push("/subwebsite/partner");
      }
      if (tab.name == "news") {
        this.$router.push("/subwebsite/news");
      }

      if (tab.name == "about") {
        this.$router.push("/subwebsite/about");
        return;
      }
      if (tab.name == "books") {
        this.$router.push("/subwebsite/certificate");
      }
      let token = this.$store.state.account.token;
      if (tab.name == "quation") {
        this.toPath("/recruit/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }
      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/home/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }
      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }
      if (tab.name == "project") {
        const w = window.open("about:blank");
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        const w = window.open("about:blank");
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },
    toPath(url) {
      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      window.open(vRouter.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
/* 头部背景图 */
.main-header {
  width: 100%;
  // background-image: url(~@/assets/images/bjtsy/hb1.png);
  background-image: url("https://oss.yanxin7.cn/2023-07-04/2c735de815fc229cb37801d40cbee557");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.search-for {
  width: 100%;
  height: 330px !important;
  color: white;
  text-align: center;
}

.nav-left {
  width: 1520px;
  margin: 0 auto;
}


.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header {
  background-color: transparent;
}

::v-deep #content.el-tabs--border-card {
  border: none;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: white;
  background-color: #0662e6;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  margin-top: 0px;
}

::v-deep #content.el-tabs--border-card>.el-tabs__header {
  height: 40px;
}

::v-deep .el-tabs__nav.is-stretch>* {
  flex: 1;
  text-align: center;
  width: 170px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-weight: bold;
  background-color: white !important;
  color: #116ae6;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  border: none;
}

::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
  background-color: transparent;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}
</style>

